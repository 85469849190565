import React from "react";

import Layout from "../components/Layout";
import Header from "../components/Header-Other-Pages";
// import Scroll from '../components/Scroll';
import Footer from "../components/Footer";
import astore from "../assets/images/app-store-badge.svg";

const PrivacyPage = () => (
  <Layout>
    <Header />

    <section className="privacy" id="">
      <div className="container">
        <div className="section-heading">
          <h2 className="text-center">PRIVACY</h2>
          <br></br>
        </div>
        <div className="row">
          <div className="col-lg-9 my-auto margin_update">
            <div className="container-fluid">
              <div className="row">
                <div className="feature-item">
                  <i className="icon-screen-smartphone text-primary"></i>

                  <p className="subheader">
                    This policy applies to all information collected or
                    submitted on Look At Me website and our apps for iPhone,
                    iPad, or any other devices and platforms.
                  </p>

                  <h3>Information we collect</h3>
                  <p className="text-muted">
                    Look At Me user settings are saved on each device
                    individually. The app keeps User defaults within the app and
                    the children data with Apple's Core Data on the device. When
                    a user purchases a subscription, the apple uses Apple's
                    StoreKit framework and the users iTunes credentials to
                    subscribe to the app.
                  </p>

                  <p className="text-muted">
                    The Look At Me app also uses RevenueCat, Inc., for
                    subscriptions. <br></br>
                    <a href="https://www.revenuecat.com">
                      https://www.revenuecat.com
                    </a>&nbsp;
                    It is a mobile framework and backend that provides reliable
                    and centralized handling of Apple and Google subscriptions.
                    Look At Me, and Etched Prints LLC takes NO responsibility or
                    liability for RevenueCat, Inc content or activities within
                    their framework and the data collected and used by
                    RevenueCat, Inc. To learn more about their Privacy Policy,
                    please check out these links. <br></br>
                    <a href="https://www.revenuecat.com/privacy">
                      https://www.revenuecat.com/privacy
                    </a>
                    <br></br>
                    <a href="https://www.revenuecat.com/dpa">
                      https://www.revenuecat.com/dpa
                    </a>
                  </p>

                  <hr></hr>

                  <h3>iCloud</h3>
                  <p className="text-muted">
                    Look At Me does not use Apple's iCloud storage service. We
                    know you might install the app on multiple iOS devices using
                    the iTunes Apple ID for your business. This Apple ID may be
                    different from your personal or employees Apple ID. So for
                    your convenience, this is the second reason children's data
                    is on each device and not stored in the cloud.
                  </p>

                  <hr></hr>

                  <h3>Ads and analytics</h3>

                  <p className="text-muted">
                    Look At Me uses Apples built-in App Analytic system.{" "}
                    <br></br>
                    Apple, Inc. does collect app analytics&nbsp;
                    <a href="https://developer.apple.com/app-store-connect/analytics/">
                      https://developer.apple.com/app-store-connect/analytics/
                    </a>
                    <br></br>
                    Please review RevenueCat, Inc policy also. <br></br>
                    <a href="https://www.revenuecat.com/privacy">
                      https://www.revenuecat.com/privacy
                    </a>
                    <br></br>
                    <a href="https://www.revenuecat.com/dpa">
                      https://www.revenuecat.com/dpa
                    </a>
                  </p>

                  <hr></hr>

                  <h3>Information usage</h3>
                  <p className="text-muted">
                    We use the information we collect to operate and improve our
                    website, apps, and customer support. We do not share
                    personal information with outside parties except to the
                    extent necessary to fulfill Look At Me's functionality. We
                    may share anonymous, aggregate statistics with outside
                    parties. We may disclose your information in response to
                    subpoenas, court orders, or other legal requirements. To
                    exercise our legal rights or defend against legal claims; to
                    investigate, prevent, or take action regarding illegal
                    activities, suspected fraud or abuse, violations of our
                    policies; or to protect our rights and property. In the
                    future, we may sell to, buy, merge with, or partner with
                    other businesses. In such transactions, user information may
                    be among the transferred assets.
                  </p>

                  <hr></hr>

                  <h3>Security</h3>

                  <p className="text-muted">
                    We implement a variety of security measures to help keep
                    your information secure. For instance, the website is HTTPS.
                  </p>

                  <hr></hr>

                  <h3>Accessing, changing, or deleting information</h3>

                  <p className="text-muted">
                    The iOS device folder stores by default a PDF of all files
                    shared. A user can go into the Look At Me settings and turn
                    this feature off.
                  </p>

                  <hr></hr>

                  <h3>Third-party links and content</h3>

                  <p className="text-muted">
                    Third-parties like Apple, Inc, and RevenueCat, Inc have
                    independent privacy policies, and we have no responsibility
                    or liability for their content or activities.
                  </p>

                  <hr></hr>
                  <h3>California Online Privacy Protection Act Compliance</h3>

                  <p className="text-muted">
                    We comply with the California Online Privacy Protection Act.
                    We, therefore, will not distribute your personal information
                    to outside parties without your consent.
                  </p>

                  <hr></hr>

                  <h3>Children’s Online Privacy Protection Act Compliance</h3>

                  <p className="text-muted">
                    We never collect or maintain information at our website from
                    those we know are under 13, and no part of our website is
                    structured to attract anyone under 13.
                  </p>

                  <hr></hr>

                  <h3>Information for European Union Customers</h3>

                  <p className="text-muted">
                    By using Look At Me and providing your information, you
                    authorize us to collect, use, and store your information
                    outside of the European Union.
                  </p>

                  <hr></hr>

                  <h3>International Transfers of Information</h3>

                  <p className="text-muted">
                    Information may be processed, stored, and used outside of
                    your location. Data privacy laws vary across jurisdictions,
                    and different rules may apply to your data depending on
                    where it is processed, stored, or used.
                  </p>

                  <hr></hr>

                  <h3>Your Consent</h3>

                  <p className="text-muted">
                    By using our site or apps, you consent to our privacy
                    policy.
                  </p>

                  <hr></hr>

                  <h3>Contact Us</h3>

                  <p className="text-muted">
                    If you have questions regarding this privacy policy, please
                    visit{" "}
                    <a href="http://llc.etchedprints.com">
                      http://llc.etchedprints.com
                    </a>
                  </p>

                  <hr></hr>

                  <h3>Changes to this policy</h3>

                  <p className="text-muted">
                    If we decide to change our privacy policy, we will post
                    those changes on this page. Summary of changes so far:
                  </p>
                  <ul>
                    <li>
                      <strong>Sept 1st, 2019</strong> First published
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">No More Paper!</h2>

            <p>
              Available on Apple&trade; iOS devices.
              <br></br>
              Download now to get started!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://apps.apple.com/us/app/look-at-me-daycare/id1254621223">
                <img src={astore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default PrivacyPage;
